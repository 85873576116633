/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

  $('.hero-slider').slick({
    dots: true,
    infinite: true,
    speed: 600,
    fade: true,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 1,
    arrows: true,
    prevArrow: '<div class="prev slider-nav"></div>',
    nextArrow: '<div class="next slider-nav"></div>',
    // adaptiveHeight: true
  });

  $(".animsition").animsition({
    inClass: 'fade-in-up-sm',
    outClass: 'fade-in-down',
    inDuration: 800,
    outDuration: 800,
    //linkElement: '.btn',
    // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
    loading: false,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: [ 'animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay : false,
    overlayClass : 'animsition-overlay-slide',
    overlayParentElement : 'body',
    transition: function(url){ window.location.href = url; }
  });

$(document).ready(function() {
    setTimeout(function() {
        $(".page-title").addClass("active")
    }, 600)
});




$('.scrolltop').hide();
$(window).scroll(function() {
    if ($(window).scrollTop() > 400) {
        $('.scrolltop').fadeIn();
    }
    else {
        $('.scrolltop').fadeOut();
    }
});


$(".scrolltop").click(function() {
    $('html, body').animate({
        scrollTop: 0
    }, 800);
});

if ($('.current_page_parent').hasClass('dropdown')) {
    $('.current_page_parent').addClass('open');
    console.log('yup');
}


(function($) {
    $('.accordion > li:eq(0) .qlink').addClass('active').next().slideDown();

    $('.accordion .qlink').click(function(j) {
        var dropDown = $(this).closest('.parent-li').find('.answer');

        $(this).closest('.accordion').find('.answer').not(dropDown).slideUp();

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).closest('.accordion').find('.qlink.active').removeClass('active');
            $(this).addClass('active');
        }

        dropDown.stop(false, true).slideToggle();

        j.preventDefault();
    });
})(jQuery);

(function () {
	$('.hamburger-menu').on('click', function() {
		$('.bar').toggleClass('animate');
        $('.mobile-menu').toggleClass('active');
	})
})();

// Scroll Reveal
window.sr = ScrollReveal({
    //reset: true,
    opacity: 0,
    scale: 1,
    duration: 1000,
    distance: '100px',
    mobile: false,
    viewFactor: 0.4
});
sr.reveal('.fadein');


$(".location-marker").click(function() {
    $("#locationSide").toggleClass("active");
});

$("#closeSide").click(function() {
    $("#locationSide").toggleClass("active");
});



(function() {

  var Modal = function() {

    var prefix = 'Modal-';

    this.Class = {
      stopOverflow: prefix + 'cancel-overflow',
      overlay: prefix + 'overlay',
      box: prefix + 'box',
      close: prefix + 'close'
    };

    this.Selector = {
      overlay: '.' + this.Class.overlay,
      box: '.' + this.Class.box,
      button: '[data-modal=button]'
    };

    this.Markup = {
      close: '<div class=" ' + this.Class.close + ' ">Close X</div>',
      overlay: '<div class=" ' + this.Class.overlay + ' "></div>',
      box: '<div class=" ' + this.Class.box + ' "></div>'
    }

    this.youtubeID = false;

  };

  Modal.prototype = {

    toggleOverflow: function() {
      $('body').toggleClass(this.Class.stopOverflow);
    },

    videoContainer: function() {
      return '<div class="video-container"><iframe id="player" src="https://www.youtube.com/embed/' + this.youtubeID + '?autoplay=1&rel=0" frameborder="0"></iframe></div>';
    },

    addOverlay: function() {

      var self = this;

      $(this.Markup.overlay).appendTo('body').fadeIn('slow', function() {
        self.toggleOverflow();
      });

      $(this.Selector.overlay).on('click touchstart', function() {
        self.closeModal();
      })

    },

    addModalBox: function() {
      $(this.Markup.box).appendTo(this.Selector.overlay);
    },

    buildModal: function(youtubeID) {

      this.addOverlay();
      this.addModalBox();

      $(this.Markup.close).appendTo(this.Selector.overlay);
      $(this.videoContainer(youtubeID)).appendTo(this.Selector.box);

    },

    closeModal: function() {

      this.toggleOverflow();

      $(this.Selector.overlay).fadeOut().detach();
      $(this.Selector.box).empty();

    },

    getYoutubeID: function() {
      return this.youtubeID;
    },

    setYoutubeID: function(href) {

      var id = '';

      if (href.indexOf('youtube.com') > -1) {
        // full Youtube link
        id = href.split('v=')[1];
      } else if (href.indexOf('youtu.be') > -1) {
        // shortened Youtube link
        id = href.split('.be/')[1];
      } else {
        // in case it's not a Youtube link, send them on their merry way
        document.location = href;
      }

      // If there's an ampersand, remove it and return what's left, otherwise return the ID
      this.youtubeID = (id.indexOf('&') != -1) ? id.substring(0, amp) : id;

    },

    startup: function(href) {

      this.setYoutubeID(href);

      if (this.youtubeID) {
        this.buildModal();
      }

    }

  };

  $(document).ready(function() {

    var modal = new Modal();

    $(modal.Selector.button).on('click touchstart', function(e) {
      e.preventDefault();
      modal.startup(this.href);
    });

  });

})(this);



$(".flip-card").click(function() {
    $(this).toggleClass('hover');
});



if ( $(window).width() > 993) {
    //Parallax
    function simpleParallax() {
        //This variable is storing the distance scrolled
        var scrolled = $(window).scrollTop() + 1;

        //Every element with the class "scroll" will have parallax background
        //Change the "0.3" for adjusting scroll speed.
        $('.scroll').css('background-position', '0' + -(scrolled * 0.3) + 'px');
    }
    //Everytime we scroll, it will fire the function
    $(window).scroll(function (e) {
        simpleParallax();
    });
}
else {
  //Add your javascript for small screens here
}



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      // Ticker
      var $ticker = $('[data-ticker="list"]'),
          tickerItem = '[data-ticker="item"]'
          itemCount = $(tickerItem).length,
          viewportWidth = 0;

      function setupViewport(){
          $ticker.find(tickerItem).clone().prependTo('[data-ticker="list"]');

          for (i = 0; i < itemCount; i ++){
              var itemWidth = $(tickerItem).eq(i).outerWidth();
              viewportWidth = viewportWidth + itemWidth;
              console.log(viewportWidth);
          }

          var fullwidth = '100%';

          var width = document.body.clientWidth;


          console.log(width);


          // $('.ticker__item').css('width', fullwidth);

          $ticker.css('width', width);
      }

      function animateTicker(){
          $ticker.animate({
              marginLeft: -viewportWidth
            }, 10000, "linear", function() {
              $ticker.css('margin-left', '0');
              animateTicker();
            });
      }

      function initializeTicker(){
          setupViewport();
          animateTicker();

      }

      initializeTicker();

    // Video Slider
    function e(t) {
        var e = t.find(".js-videoIframe"),
            i = e.data("src");
        t.addClass("videoWrapperActive"), e.attr("src", i)
    }

    var slickSlider = $('.videoslider').slick({
    // $(".videoslider").slick({
        dots: !1,
        arrows: true,
        appendArrows: $('.arrows'),
        fade: !0,
        slidesToShow: 1
    });
    $(document).on("click", ".js-videoPoster", function(i) {
        i.preventDefault();
        var s = $(this),
            n = s.closest(".js-videoWrapper");
        e(n), $(".videoWrapper").addClass("active")
    });
    $(document).on("click", ".slick-arrow", function() {
        $(".videoWrapper").hasClass("active") && ($(".videoWrapper").removeClass("active"), $(".videoWrapper").removeClass("videoWrapperActive"))
    });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'about_us': {
      init: function() {

        $(".about-menu").hide();

        $(window).load(function(){
            setTimeout(function() {
                $(".about-menu").fadeIn("fast");
            }, 1000)
        });

      }
    },

    'blog': {
      init: function() {


        (function ($) {
            'use strict';

            $(window).load(function(){

				var $grid = $('.grid').masonry({
				  // set itemSelector so .grid-sizer is not used in layout
				  itemSelector: '.grid-item',
				  // use element for option
				  columnWidth: '.grid-sizer',
				  percentPosition: true
				})

				$grid.imagesLoaded().progress( function() {
				  $grid.masonry();
				});

            });

        }(jQuery));

      }
    },

    'archive': {
      init: function() {


			var $grid = $('.grid').masonry({
			  // set itemSelector so .grid-sizer is not used in layout
			  itemSelector: '.grid-item',
			  // use element for option
			  columnWidth: '.grid-sizer',
			  percentPosition: true
			})

			$grid.imagesLoaded().progress( function() {
			  $grid.masonry();
			});



/*
$('.grid').masonry({
itemSelector: '.grid-item',
columnWidth: '.grid-sizer',
percentPosition: true
});
*/

      }
    },

    'work_with_us': {
      init: function() {

    // Video Slider
    function e(t) {
        var e = t.find(".js-videoIframe"),
            i = e.data("src");
        t.addClass("videoWrapperActive"), e.attr("src", i)
    }

    var slickSlider = $('.videoslider').slick({
    // $(".videoslider").slick({
        dots: !1,
        arrows: true,
        appendArrows: $('.arrows'),
        fade: !0,
        slidesToShow: 1
    });
    $(document).on("click", ".js-videoPoster", function(i) {
        i.preventDefault();
        var s = $(this),
            n = s.closest(".js-videoWrapper");
        e(n), $(".videoWrapper").addClass("active")
    });
    $(document).on("click", ".slick-arrow", function() {
        $(".videoWrapper").hasClass("active") && ($(".videoWrapper").removeClass("active"), $(".videoWrapper").removeClass("videoWrapperActive"))
    });

      }
    },

    // About us page, note the change from about-us to about_us.
    'page_template_template_location_php': {
      init: function() {
        // JavaScript to be fired on the about us page

        $(".locations-menu").hide();

        $(window).load(function(){
            setTimeout(function() {
                $(".locations-menu").fadeIn("fast");
            }, 1000)
        });

(function($) {

/*
*  render_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function render_map( $el ) {

	// var
	var $markers = $el.find('.marker');

	// vars
	var args = {
		zoom		: 14,
		center		: new google.maps.LatLng(0, 0),
        scrollwheel : false,
		mapTypeId	: google.maps.MapTypeId.ROADMAP,
        styles      : [{"featureType":"landscape","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]}]
	};

	// create map
	var map = new google.maps.Map( $el[0], args);



	// add a markers reference
	map.markers = [];

	// add markers
	$markers.each(function(){

    	add_marker( $(this), map );

	});

	// center map
	center_map( map );

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker( $marker, map ) {

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

var image = new google.maps.MarkerImage(
    "../wp-content/themes/ets/dist/images/map-marker-green.png",
    null, /* size is determined at runtime */
    null, /* origin is 0,0 */
    null, /* anchor is bottom center of the scaled image */
    new google.maps.Size(42, 42)
);

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
        icon        : image,
		map			: map
	});

	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		// show info window when marker is clicked
		google.maps.event.addListener(marker, 'click', function() {

			infowindow.open( map, marker );

		});
	}

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length == 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}

$(document).ready(function(){

	$('.acf-map').each(function(){

		render_map( $(this) );

	});

});

})(jQuery);


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
